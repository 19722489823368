// Superadmin header configuration
export const superadmin_header = [
    {
        id: 1,
        name: 'Dashboard',
        icon: "material-symbols-outlined",
        route: "/superadmin/dashboard",
    },
    {
        id: 2,
        name: 'Admin',
        icon: "fe fe-users",
        route: "/superadmin/admin",
    },
    {
        id: 3,
        name: 'Transaction',
        icon: "fe fe-dollar-sign",
        route: "/superadmin/transaction",
    },
    {
        id: 4,
        name: 'Available Positions',
        icon: "fa fa-home",
        route: "/superadmin/position",
    },
    {
        id: 5,
        name: 'Broadcast',
        icon: "fe fe-dollar-sign",
        route: "/superadmin/broadcast",
    },
    {
        id: 6,
        name: 'Hold off',
        icon: "fe fe-dollar-sign",
        route: "/superadmin/holdoff",
    },
    // {
    //     id: 7,
    //     name: 'Currency Setup',
    //     icon: "fe fe-dollar-sign",
    //     route: "/superadmin/currency",
    // },
    
];



// Admin header configuration
export const admin_header = [
    {
        id: 1,
        name: 'Dashboard',
        icon: "fa-solid fa-house",
        route: "/admin/dashboard",
    },
    {
        id: 2,
        name: 'Employees',
        icon: "fe fe-users",
        route: "/admin/employee",
    },
    {
        id: 3,
        name: 'Users',
        icon: "fe fe-users",
        route: "/admin/users",
    },
    {
        id: 4,
        name: 'Transaction',
        icon: "fe fe-dollar-sign",
        route: "/admin/transaction",
    },
    {
        id: 5,
        name: 'Reports',
        icon: "fe fe-file-text",
        route: "/admin/report",
    },
    {
        id: 6,
        name: 'Trade History',
        icon: "fe fe-activity",
        route: "/admin/tradehistory",
    },
    {
        id: 7,
        name: 'Login Status',
        icon: "fe fe-check-square",
        route: "/admin/loginstatus",
    },
    {
        id: 8,
        name: 'Available Positions',
        icon: "fe fe-target",
        route: "/admin/position",
    },
    {
        id: 9,
        name: 'Withdrawal',
        icon: "fe fe-arrow-down-circle",
        route: "/admin/withdrawal",
    },
    {
        id: 10,
        name: 'Deposit',
        icon: "fe fe-arrow-up-circle",
        route: "/admin/deposit",
    },
    {
        id: 11,
        name: 'Broadcast',
        icon: "fe fe-arrow-up-circle",
        route: "/admin/broadcast",
    },
    {
        id: 12,
        name: 'Hold off',
        icon: "fe fe-arrow-up-circle",
        route: "/admin/holdoff",
    },
    {
        id: 11,
        name: 'Currency Setup',
        icon: "fe fe-arrow-up-circle",
        route: "/admin/currency",
    },
    {
        id: 12,
        name: 'Sign Up Request',
        icon: "fa fa-dollar-sign",
        route: "/admin/signup",
    },
];


export const employee_header = [
    {
        id: 1,
        name: 'Dashboard',
        icon: "fa-solid fa-house",
        route: "/employee/dashboard",
    },
   
    {
        id: 2,
        name: 'Users',
        icon: "fe fe-users",
        route: "/employee/users",
    },
    // {
    //     id: 3,
    //     name: 'Transaction',
    //     icon: "fe fe-dollar-sign",
    //     route: "/employee/transaction",
    // },
    // {
    //     id: 4,
    //     name: 'Reports',
    //     icon: "fe fe-file-text",
    //     route: "/employee/report",
    // },
    // {
    //     id: 5,
    //     name: 'Trade History',
    //     icon: "fe fe-activity",
    //     route: "/employee/tradehistory",
    // },
    // {
    //     id: 6,
    //     name: 'Login Status',
    //     icon: "fe fe-check-square",
    //     route: "/employee/loginstatus",
    // },
    {
        id: 7,
        name: 'Available Positions',
        icon: "fe fe-target",
        route: "/employee/position",
    },
    // {
    //     id: 8,
    //     name: 'Withdrawal',
    //     icon: "fe fe-arrow-down-circle",
    //     route: "/employee/withdrawal",
    // },
    // {
    //     id: 9,
    //     name: 'Deposit',
    //     icon: "fe fe-arrow-up-circle",
    //     route: "/employee/deposit",
    // },
    // {
    //     id: 10,
    //     name: 'Broadcast',
    //     icon: "fe fe-arrow-up-circle",
    //     route: "/employee/broadcast",
    // },
   
];